.app-container {
    background-image: url(../images/bg-image.jpg);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plans-container {
    width: 80%;
    height: 70%;
    background-color: rgb(229, 223, 215, 80%);
    border-radius: 20px;
    padding-top: 30px;
}

.plan-lan-head p {
    text-align: center;
}

.plan-lan-head-lbl {
    font-size: 30px;
}

.plan-lan-head-lbl2 {
    font-size: 20px;
}



.first {
    height: 42%;
    background-image: linear-gradient(to bottom right, #D37E39, #4D5742);
    padding: 22px;
}

.first p {
    color: #FFFF;
}

.sec {
    height: 42%;
    background-image: linear-gradient(to bottom right, #222222, #1A1A1A);
    padding: 22px;
}

.sec p {
    color: #FFFF;
}

.thir {
    height: 35%;
    background-image: linear-gradient(to bottom right, #FEF8F0, #FEF8F0);
    padding: 22px;
}

.card-head {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
}

.card-sub-head {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.card-head2 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}

.fe-container {
    height: 70%;
    padding: 14px 26px;
}

.fe-container ul {
    padding-left: 0;
}

.fe-container ul li {
    font-size: 16px;
    margin: 10px 0;
}

.fe-lbl {
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin: 0;
}

.check-img {
    max-width: 23px;
    margin-right: 10px;
}



.plans-container {
    width: 94%;
    height: 95%;
    background-color: rgb(229, 223, 215, 80%);
    border-radius: 20px;
    padding-top: 30px;
    overflow-y: scroll;
}

.plans-arr {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5%;
    margin-top: 60px;
}

.plan-box {
    width: 100%;
    background-color: #FFFF;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    margin: 10px 0px;
}

@media only screen and (min-width: 900px) {
    .plans-arr {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 70%;
        padding: 0 5%;
        margin-top: 60px;
    }

    .plan-box {
        /* height: 100%; */
        width: 24%;
        background-color: #FFFF;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .plans-container {
        width: 80%;
        height: 86%;
        background-color: rgb(229, 223, 215, 80%);
        border-radius: 20px;
        padding-top: 30px;
        overflow-y: hidden;
    }

}

.success-container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-image: url(../images/bg-image.jpg);
    padding: 0px 20px;
}

.masge-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 33px;
    border-radius: 25px;
    background-color: rgb(189, 184, 177, 90%);
    width: 377px;
}

.masge-card img {
    vertical-align: middle;
    max-width: 82px;
    padding: 20px 8px;
    background: #F5BD3E;
    border-radius: 50%;
}

.failed-img {
    vertical-align: middle;
    max-width: 82px;
    padding: 20px 18px;
    background: white;
    border-radius: 50%;
}

.txt-center {
    text-align: center;
}

.succes-lbl {
    font-size: 25px;
    color: white;   
}

.info-lbl {
    font-size: 18px;
    color: white;
}

.coupon-code-container {
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 0px;
}

.coupon-code-input {
    width: 300px; /* Adjust width as needed */
    height: 40px;
    padding: 8px 12px;
    border: none;
    border-radius: 20px;
    /* background-color: rgba(255, 255, 255, 0.9); Slight transparency */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    font-size: 16px;
    outline: none;
}

.coupon-code-input::placeholder {
    color: #999; /* Placeholder text color */
}

.coupon-code-input:focus {
    border: 2px solid #3399cc; /* Highlight border color on focus */
}