@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,700,900');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff9f1;
}

html {
  background: #fff9f1;
  min-height: 100%;
}

.mb-0 {
  margin-bottom: 0px !important;
}


a {
  text-decoration: none !important;
}

a.white-text {
  color: #fff;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input,
.input-group-text {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #ccc;
  box-shadow: none;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #898989;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

:focus-visible {
  outline: none !important;
  background: transparent;
  border: 0
}

.openSidebarMenu {
  display: none;
}

button.button_common.btn.btn-primary {
  cursor: pointer;
}

.btn-primary:focus-visible,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.h-dashboard .dropdown .btn-primary:hover,
.btn:hover,
.btn:active,
.btn:focus-visible,
.app .form-select:focus {
  box-shadow: none !important;
  outline: 0;
  border: none;
}

.dashboard .button_common.btn.btn-primary:focus,
.dashboard .button_common.btn.btn-primary:hover,
.dashboard .button_common.btn.btn-primary.with-focus-visible:focus:not(:focus-visible),
.dashboard .nav-link:hover,
.dashboard.nav-link:focus,
.dashboard a:focus-visible,
.dashboard a:focus {
  box-shadow: none !important;
  outline: 0;
  border: none;
  border: none;
  background: #f2bb3f !important;
}

button.button_common.btn.btn-primary.with-focus-visible:focus-visible,
.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.nav-link:focus-visible,
button.button_common.btn.btn-primary.with-focus-visible:moz-focusring,
.btn-primary:focus-visible,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.h-dashboard .dropdown .btn-primary:hover,
.btn:hover,
.btn:active,
.btn:focus-visible {
  box-shadow: none !important;
  outline: 0;
  border: none;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.send-box ::placeholder {
  color: #000;
  opacity: 1;
  /* Firefox */
  box-shadow: none;
}

.send-box ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #000;
  box-shadow: none;
}


.ai-div .send-box ::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
  box-shadow: none;
}

.a.dropdown-item:focus {
  background-color: transparent !important;
}

.ai-div .send-box ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff;
  box-shadow: none;
}

button.transparent-btn.btn.btn-primary {
  background: transparent !important;
  border: 1px solid #f2bb3f !important;
  max-width: 155px;
  color: #f2bb3f;
  margin-right: 10px;
  padding: 8px 15px;
  border-radius: 20px;
}

.transparent-btn:active,
button.transparent-btn.btn.btn-primary:hover {
  background: #f2bb3f !important;
  border-radius: 20px;
  border: 1px solid #f2bb3f !important;
  color: #fff;
}

button.btn.p-0.m-0.btn.btn-primary {
  background: transparent;
  border: 0;
}

.toggleIcon.aitherapist {
  left: 0 !important;
}

.toggleIcon.aitherapist .ai-div .dropdown-menu.show {
  background: #ffff !important;
  height: auto;
  border-radius: 20px;
  padding: 10px;
}

.toggleIcon.aitherapist .badge-text {
  width: 73px;
  background: aliceblue;
}

.toggleIcon.aitherapist .toggle-menu {
  display: none;
}

.toggleIcon.aitherapist h2.heading {
  min-width: 164px;
  margin-left: 15px;
}

.dropdown-menu.show {
  right: 0 !important;
  transform: inherit !important;
  height: auto;
  border-radius: 20px;
}

.toggleIcon.aitherapist .ai-div .dropdown-menu.show {
  right: 0 !important;
  top: 27px !important;
  transform: inherit !important;
}

.toggleIcon.aitherapist .ai-div a.dropdown-item {
  padding: 0;
}

.toggleIcon.aitherapist .ai-div .dropbtn {
  padding-right: 18px;
  max-width: 35px;
  margin-right: 5px;
}

svg.icon.icon-lg {
  width: 26px;
  margin: 5px;
}

.account-dropdown .dropdown-menu.show {
  inset: 0px auto auto 0px !important;
  transform: translate3d(-78%, -2px, 44px) !important;

}

.toggleIcon.aitherapist li.notification-dropdown .dropdown-menu.show {
  min-width: 262px !important;
  transform: translate3d(-209.25px, -2px, 44px) !important;
  inset: 0px auto auto 0px !important;
}

.h-dashboard li.notification-dropdown .dropdown-menu.show p,
.dropdown-header p {
  margin: 5px 0;
  color: #fff !important;
}

/* / scrollbar / */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}