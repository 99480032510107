.gender-bubbles {
    display: flex;
    gap: 7px;
    align-items: flex-start;
  }
  
  .gender-bubbles label {
    display: flex;
    align-items: center;
  }
  
  .bubble {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #1d2129;
    font-weight: 400;
    background-color:transparent; /* Same background color for all bubbles */
  }
  
  /* Adjust the size of the input elements */
  .gender-bubbles input[type="radio"] {
    width: 12px; /* Adjust the width */
    height: 12px; /* Adjust the height */
    margin-right: 2px;
    /* margin-left: 20px; */
    margin-bottom: 10px;
  }
  
.terms-condition{
  display: flex;
}
  /* CSS for smaller checkbox and aligning terms and conditions text */
.terms-condition label {
  display: inline-flex;
  align-items: center;
}

/* Media query for mobile devices
@media (max-width: 767px) {
  /* Reduce space between checkbox and text 
  .terms-condition label {
    margin-bottom: 0; /* Remove default margin 
  }
}*/

.terms-condition input[type="checkbox"] {
  width: 16px; /* Adjust the width */
  height: 16px; /* Adjust the height */
  margin-right: 8px; /* Adjust margin as needed */
}

/* Hyperlink style for terms and conditions text */
.terms-condition a {
  color: #007bff; /* Set hyperlink color */
  text-decoration: none; /* Remove underline by default */
}

.terms-condition a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* CSS for Resend OTP text */
.resend-otp-text {
  color: #007bff; /* Light blue color */
  cursor: pointer; /* Show pointer cursor on hover */
}

.resend-otp-text:hover {
  text-decoration: underline; /* Underline on hover */
}