.table-container {
    padding: 10px;
    background: rgb(229,223,215);
    max-height: 850px;
    overflow: hidden;
    overflow-y: scroll;
    /* margin-top: 187px; */
    width: 100%;
    min-width: 372px;
    overflow: scroll;
}


.bck-lbl {
    margin: 10px;
    font-size: 15px;
    font-weight: 500;
    height: 39px;
    padding: 7px;
}

.common-table {
    width: 100%;
    min-width: 700px;
    border-collapse: separate;
    border-spacing: 0;
    position: sticky;
    top: 0;
    z-index: 14;
}

.common-table-2 {
    width: 100%;
    border-collapse: separate;
  border-spacing: 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-head {
    background-color: rgb(255,255,255);
    height: 60px;
}

.table-deff {
    background-color: rgb(255,249,241);
}
tr {
    height: 60px;
    border-spacing: 0 1em;
}
th {
    padding-left: 10px;
}
td {
    padding-left: 10px;
}
table { 
    border-collapse: separate; 
    border-spacing: 0 10px !important; 
}
th:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px; 
    border-bottom-left-radius: 20px;
}
th:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px; 
    border-top-right-radius: 20px; 
}
td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 10px;
}
td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px; 
    border-bottom-left-radius: 20px;
}
td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px; 
    border-top-right-radius: 20px; 
}