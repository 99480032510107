/* @import url(https://db.onlinewebfonts.com/c/b6a82233a2e528638ee7c5aedc864270?family=Adobe+Clean+Serif); */
@import "./plan";
@import "./commonTable.css";

/* Common-css */
.App {
  /* background: #fff9f1; */
  background-image: url(/public/images/pink-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: left bottom;
  min-height: 100vh;
}

.bg-pink {
  background: #fff9f1 !important;
}

.bg-grey {
  background: rgba(192, 192, 192) !important;
}

.shadow-pink {
  box-shadow: 0 0 10px 5px #f2f2f2 !important;
  border-radius: 20px;
}

.grey-div {
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 20px;
  padding: 10px;
}

.green-color {
  color: #125049;
}

.green-bg {
  background-color: #125049 !important;
  color: #fff !important;
}

body,
.App,
#root,
.h-dashboard,
html,
div#sidebar-wrapper {
  height: 100vh;
  height: 100%;
  max-height: 100%;
}

.App form label,
.App a {
  text-align: left;
  font-size: 14px;
  color: #353433;
  font-weight: 500;
}

h1.heading {
  text-align: left;
  font-size: 28px;
  margin: 10px auto;
  color: #0a0b0d;
  font-weight: 700;
  font-family: "Adobe Clean Serif";
}

h2.heading {
  text-align: left;
  font-size: 26px;
  margin: 20px auto;
  color: #3d3c3a;
  font-family: "Adobe Clean Serif";
  font-weight: 600;
}

.secheading {
  text-align: left;
  font-size: 24px;
  margin: 20px auto;
  color: #125049;
  font-family: "Adobe Clean Serif";
  font-weight: 600;
  margin: 0;
}

.arrow {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  margin-right: 5px;
}

.arrow-left {
  transform: rotate(-135deg);
}

.subheading {
  text-align: left;
  font-size: 24px;
  color: #f2bb3f;
  font-weight: 700;
  font-family: "Adobe Clean Serif";
}

p {
  text-align: left;
  font-size: 14px;
  color: #3d3c3a;
}

.logo {
  max-width: 200px;
  text-align: left;
  margin: 10px 0;
}

p.account-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #898989;
  margin: 10px 0 0;
}

.button_common.btn.btn-primary,
.dashboard .button_common.btn.btn-primary,
.button_common {
  border-radius: 20px;
  background-color: #f2bb3f;
  border: 0 !important;
  outline: none;
  color: #0a0b0d;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin: 20px auto;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  display: block;
}

.button_common:active {
  transform: translateY(2px);
  background-color: #f6cd6e;
}

.error {
  color: red;
  text-align: left;
  margin: auto;
  font-size: 12px;
  padding: 0px 1rem;
}

/* login-page  */
.login-page .register-text {
  text-align: center;
  margin: 5px auto;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: #424242;
  text-decoration: underline !important;
}

.left-text p {
  max-width: 305px;
}

.register {
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 8px;
  padding: 20px 20px;
  min-height: 400px;
  max-width: 400px;
}

.register>form input,
.register>form .react-tel-input .form-control {
  border-radius: 20px;
  background-color: #fff9f1;
  border: 0;
  outline: none;
  color: #1d2129;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin: 10px 0 5px;
  height: 45px;
}

.Avail-Time-Text {
  color: #bebebe;
}

.register a {
  text-align: center;
}

.react-tel-input.form-control {
  margin: 0;
  padding: 0;
  background: transparent;
}

.register .react-tel-input .flag-dropdown {
  background-color: #fff;
  border: 0;
  border-radius: 20px 0 0 20px;
  min-width: 40px;
  height: 45px;
}

.register .react-tel-input .selected-flag:hover,
.register .react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
  border-radius: 20px;
  border: 0;
}

.ph-input input {
  padding-left: 50px !important;
}

.register .input-group-text {
  border-radius: 20px;
  border: 0;
  outline: none;
  height: 45px;
  margin: 10px 0 5px;
}

.stage {
  border: 0;
  text-align: center;
  margin: 15px;
}

.stage span {
  display: none;
}

.login-page .stage button,
.stage div {
  background: transparent !important;
  margin: 0 !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.login-page .google-auth__button {
  border: 0;
}

.main-container {
  display: flex;
  height: 100vh;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
}

.userprofile .main-container {
  max-width: 100%;
}

.right-div .form-label {
  margin-bottom: 0;
}

.left-arrow {
  color: black;
  margin-top: -10px;
  margin-right: 10px;
}

/* otp */
.otp-div {
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 8px;
  padding: 40px 20px 60px;
  max-width: 350px;
}

.icon-div {
  display: inline;
  background: #ccc;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  text-align: center;
  padding: 3px 0;
  margin-right: 10px;
}

.pricing a,
.success a {
  text-decoration: none;
  color: #898989;
  font-weight: 500;
  line-height: 30px;
}

.otp-div p {
  max-width: 305px;
  font-size: 14px;
  color: #424242;
  margin: 30px 0 20px;
}

a.otp-text.text-center {
  text-align: center;
  width: 100%;
  display: inline-block;
  color: #3d3c3a;
  text-decoration: underline !important;
}

.otp-container {
  display: flex;
  align-items: center;
}

.otp-input {
  width: 55px;
  height: 55px;
  margin: 0 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  font-size: 24px;
  text-align: center;
  outline: none;
}

.otp-input:focus {
  border-color: #007bff;
}

.otp-input.completed {
  border-color: #28a745;
}

/* success */
.success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.success p {
  text-align: center;
}

.success a {
  border-radius: 20px;
}

.success .item {
  background-color: rgba(192, 192, 192, 0.2);
  border-radius: 20px;
  width: 330px;
  height: 330px;
  text-align: center;
  padding: 46px;
}

.success i.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
}

.right-div {
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 8px;
  padding: 20px;
  min-height: 500px;
  max-width: 400px !important;
  float: left;
}

.right-div>form input {
  border-radius: 20px;
  background-color: #fff9f1;
  border: 0;
  outline: none;
  color: #1d2129;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin: 5px 0 5px;
}

/* Theme */
.dark-div.pricing-card-heading-lite,
.gradient-div.pricing-card-heading-pro,
.light-div.pricing-card-heading-free {
  width: 100%;
  display: inline-block;
  height: 200px;
  margin-bottom: 15px;
}

.inner {
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  min-height: 400px;
  padding: 50px 100px;
}

/* plan */
.plan-div {
  background: #fff;
  padding: 10px !important;
}

.plan-div .gradient {
  width: 100%;
  background: #f2bb3f;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.plan-div .light {
  width: 100%;
  background: #fff9f1;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.plan-div .dark {
  width: 100%;
  background: #3d3c3a;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.pricing-card-heading {
  padding: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
}

.pricing-card-heading-free {
  background-color: #f8fafe;
  color: #3d3c3a;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.pricing-card-heading-lite {
  background: linear-gradient(90deg,
      rgba(194, 209, 255, 1) 0%,
      rgba(179, 114, 254, 1) 100%);
  color: #fff;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.pricing-card-heading-pro {
  background: linear-gradient(90deg,
      rgba(255, 177, 195, 1) 0%,
      rgba(255, 203, 112, 1) 100%);
  color: #fff;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.bg-white.d-block.p-4 {
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border-radius: 0px 0px 20px 20px;
}

.pricing-card-list {
  text-align: left;
  background: #fff;
  padding: 20px;
  margin: 0;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border-radius: 0px 0px 20px 20px;
}

.theme .pricing-card-heading-pro,
.theme .pricing-card-heading-lite,
.theme .pricing-card-heading-free {
  border-radius: 20px;
}

.pricing-card-list-item {
  position: relative;
  list-style: none;
  padding-left: 30px;
  font-size: 16px;
  line-height: 32px;
  color: #898989;
}

.icon-yes::before {
  position: absolute;
  left: 6px;
  top: 8px;
  display: inline-block;
  content: "";
  transform: rotate(45deg);
  height: 17px;
  width: 9px;
  border-bottom: 3px solid #78b13f;
  border-right: 3px solid #78b13f;
}

.black-text {
  color: #000;
}

.profile .card-body.payment-card-body {
  padding: 20px;
}

.icon-no {
  position: relative;
}

.icon-no::before,
.icon-no:after {
  content: "\f00d";
  position: absolute;
  left: 9px;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: red;
  top: 12px;
}

.icon-no:before {
  transform: rotate(45deg);
}

.icon-no:after {
  transform: rotate(-45deg);
}

.pricing-card-btn:hover {
  background-color: rgb(228, 228, 240);
}

.pricing-card-highlight .pricing-card-btn {
  border: none;
  color: #fff;
  background-color: #27264b;
}

.pricing-card-highlight .pricing-card-btn:hover {
  background-color: #9390db;
}

/* dashboard */
.dashboard .slick-list div img {
  max-height: 170px;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 170px;
  background: #fff9f1;
  border-radius: 20px;
}

.support-div-text {
  padding: 0px 5px;
}

.dashboard .slick-list .inner-div {
  padding: 5px;
  border-radius: 20px;
  background: #fff9f1;
}

.dashboard .card-body {
  background: #fff9f1;
  padding: 5px;
  border-radius: 20px;
}

.focussed-div-text {
  padding: 0px 10px;
}

.dashboard h3 {
  font-size: 18px;
  margin: 0 0 5px;
  text-align: left;
}

p.mb-1.focussed-heading {
  text-align: left;
  font-size: 18px;
  color: #125049;
  font-family: "Adobe Clean Serif";
  font-weight: 600;
  margin: 0;
}

.dashboard {
  margin: 0;
  padding: 0;
  margin: 0px auto;
  overflow: hidden;
  max-width: 1350px;
}

.dashboard-inner.ai-div.d-flex.justify-content-center {
  background: aliceblue;
  min-height: 100%;
}

.dashboard .slick-list {
  margin: 0px auto;
}

.dashboard .inner {
  padding: 0px 20px 10px;
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  margin: 20px auto;
  border-radius: 20px;
}

.dashboard .slick-prev {
  display: none !important;
  left: 15px;
}

.dashboard .slick-next {
  top: 109%;
  right: 7%;
  display: none !important;
}

.dashboard .button_common.btn.btn-primary {
  float: right;
  max-width: 160px;
}

.dashboard .slick-prev:before,
.dashboard .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.support-div,
.focussed-div {
  position: relative;
}

.therapist-doctor-text-div {
  min-height: 110px;
}

.support-text,
.focussed-text {
  position: absolute;
  left: 10px;
  top: 10px;
  background: #fff;
  padding: 3px 9px;
  border-radius: 20px;
  font-size: 12px;
}

.startDate,
.endDate {
  font-size: 13px;
  color: #898989;
  line-height: 16px;
}

.dashboard .slick-next:before {
  content: "Next";
  border-radius: 20px;
  background-color: #f2bb3f;
  border: 0;
  outline: none;
  color: #3d3c3a;
  padding: 9px 45px 14px;
  font-size: 16px;
  opacity: 1;
  font-family: "Adobe Clean Serif";
}

.therapist-doctor .inner-div .img {
  margin-right: 10px;
  float: left;
}

.skip {
  align-items: center;
  justify-content: end;
}

.skip a {
  text-decoration: none !important;
}

.skip a {
  text-decoration: none !important;
  display: block;
  text-align: center;
}

/* therapist */
.therapist .inner-div img {
  max-height: 200px;
  overflow: hidden;
  padding: 5px;
  min-height: auto;
  background: #fff9f1;
  border-radius: 20px;
  min-width: 90px;
  min-height: 90px;
  background: #ccc;
}

.therapist p {
  margin-bottom: 0;
}

li {
  list-style: none;
}

.theme h6 {
  color: #0a0b0d;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

.therapist .logo {
  max-width: 130px;
  text-align: left;
  margin: 10px 0;
  float: right;
  height: auto;
  min-height: auto;
  margin-left: 30px;
}

.inner-div.image-div {
  background: linear-gradient(90deg,
      rgba(255, 177, 195, 1) 0%,
      rgba(255, 203, 112, 1) 100%);
  padding: 30px;
}

.inner-div.image-div p {
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  margin: 20px 0;
  display: inline-block;
  width: 100%;
}

.therapist .navbar-nav {
  align-items: center;
}

.therapist .circle-img {
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 100%;
}

.therapist .form-control {
  border-radius: 20px;
  background-color: #fff;
  border: 0;
  outline: none;
  color: #1d2129;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin: 0;
  height: 45px;
}

.slick-initialized .slick-slide {
  padding: 5px;
}

.therapist .inner-div {
  background: #fff9f1;
  padding: 10px;
  border-radius: 20px;
}

.therapist .inner-div .slot {
  margin: 10px;
}

.appointmentscheduler .inner-div {
  min-height: 360px;
}

.therapist .navbar-expand {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.top-bar a {
  width: 36px;
  height: 36px;
  padding: 6px 0;
  border-radius: 100%;
  background: #fff;
  border-radius: 100%;
  text-align: center;
}

.therapist .react-calendar__tile--active:enabled:hover,
.therapist .react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile--hasActive,
.btn-primary:hover {
  background: #f2bb3f !important;
  border-radius: 20px;
  border: 1px solid #f2bb3f !important;
}

.therapist .react-calendar__navigation {
  background: #f2bb3f !important;
  border-radius: 10px;
}

.dashboard.therapist button.transparent-btn.btn.btn-primary {
  margin-right: 0;
  max-height: 35px;
  padding: 3px 15px;
  min-width: 100%;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.slot {
  cursor: pointer;
  padding: 10px;
  border: 0;
  margin: 5px;
  display: inline-block;
  background: #fff;
  border-radius: 20px;
  min-width: 105px;
  text-align: center;
  margin: 0px;
  color: #205a53;
}

.slot-container.inner-div p {
  padding: 20px 020px 0;
  color: #828583;
}

.slot.selected {
  background-color: #e3e3e3;
  color: #a9aeb2;
}

.therapist .react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  border: 0;
}

.therapist abbr[title] {
  text-decoration: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.inner-div.image-div {
  background: linear-gradient(90deg, #f1ab57, rgba(255, 203, 112, 1) 100%);
  padding: 30px;
}

.next-btn {
  min-width: 144px;
  margin-left: 40px !important;
}

.bg-pink.grey-div-bg {
  border-radius: 20px;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 28px;
}

.grey-div label {
  display: inline-block;
  margin-left: 10px;
}

.grey-div p {
  font-size: 15px;
  color: #353433;
  font-weight: 500;
}

.welltest-table {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
}

.welltest-table th {
  font-weight: 500;
}

.welltest-table th.f-th {
  font-weight: 400;
  text-align: left;
  max-width: 227px;
  font-size: 14px;
  padding: 10px !important;
  border: 0 !important;
}

.welltest-table table.table th {
  padding: 20px;
  border: 0;
}

.welltest-table table.table {
  min-width: 1029px;
  overflow: scroll;
  text-align: center;
}

.welltest-table table.table td.bold {
  text-align: left;
  font-weight: 500;
}

.welltest-table table.table> :not(caption)>*>* {
  border: 0;
  border-left: 1px solid #ccc;
}

.b-l {
  border: 0px solid #ccc !important;
}

.color-option {
  height: 35px;
  width: 35px;
  list-style: none;
  border-radius: 4px;
  margin: 7px;
  transition: 0.2s;
  cursor: pointer;
}

.color-option:hover {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
}

.color-option.is-selected {
  transform: scale(1.1);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
}

.color-option:nth-child(1) {
  background: #5b00b7;
}

.color-option:nth-child(2) {
  background: #3a68b7;
}

.color-option:nth-child(3) {
  background: #34b7b0;
}

.color-option:nth-child(4) {
  background: #4f8f44;
}

.color-option:nth-child(5) {
  background: #c0582f;
}

.color-option:nth-child(6) {
  background: #a83030;
}

.color-option:nth-child(7) {
  background: #994385;
}

* {
  box-sizing: border-box;
}

.color-grid {
  margin: 0;
  padding: 0;
}

h1 {
  transition: color 0.2s;
}

.group {
  padding: 20px;
  border-radius: 7px;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
}

/******  responsive  ********/

@media only screen and (max-width: 1180px) {
  .dashboard .inner {
    padding: 10px;
    margin: 10px 0;
  }

  .container-fluid.dashboard.therapist.appointmentscheduler .inner {
    padding: 0px;
    margin: 0px 0;
  }

  .therapist .logo {
    max-width: 148px;
    margin-left: 10px;
  }

  .inner-div.image-div {
    padding: 10px;
  }

  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }

  .chat .chat-history {
    padding: 0;
  }

  .inner {
    padding: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .therapist .logo {
    max-width: 91px;
    margin-left: 1px;
  }

  .therapist .form-control {
    max-width: 173px;
  }

  .dashboard .slick-list div img {
    max-height: 180px;
    min-height: 180px;
  }

  .row.d-flex.justify-content-center.inner {
    padding: 20px 0;
  }

  .therapist .form-control {
    width: auto;
    margin: 10px;
    max-width: 200px;
    padding: 8px;
    font-size: 12px;
    height: 34px;
  }

  .top-bar a {
    background: transparent;
    width: 17px;
  }

  .dashboard .pagination {
    display: none !important;
  }

  .main-container {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-bottom: 40px;
  }

  h2.heading,
  .secheading {
    font-size: 24px;
  }
}

#sidebar-wrapper .nav-link:focus,
#sidebar-wrapper .nav-link:hover {
  background-color: #f2bb3f;
}

#dropdownMenuLink {
  background: transparent;
  border: 0;
}

#sidebar-wrapper ul.dropdown-menu {
  border-radius: 0;
  transform: translate(0px, 60px) !important;
  min-width: 95%;
  background: #ccc;
  border: 0;
  margin: 0px 5px !important;
}

#sidebar-wrapper .nav-link {
  color: #1e2125;
  padding: 15px;
}

.page-heading {
  font-size: 28px;
  font-weight: 700;
  color: #686868;
}

.dropdown-menu {
  padding: 0;
}

ol,
.list-group ul {
  padding-left: 0;
}

#sidebar-wrapper .dropdown-menu-list .d-icon {
  max-width: 20px;
  margin-right: 10px;
  float: left;
}

.datepicker-cell.day {
  font-size: 14px;
  color: #f2bb3f;
}

.sidebar-main-heading {
  font-size: 18px;
  padding: 8px 0px 7px;
  font-weight: 500;
  line-height: 28px;
  color: #0a0b0d;
  text-transform: uppercase;
}

.sidebar-subheading {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #0a0b0d;
  text-decoration: underline;
  text-transform: uppercase;
}

/*Profile Pic Start*/
img.User {
  max-width: 40px;
  margin: 0 0px;
}

.pic-div {
  background: #004169;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin: 0px auto;
}

.d-head {
  color: #004169;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.collapse:not(.show) {
  display: block !important;
}

.h-dashboard img.icon {
  width: 16px;
  margin-right: 10px;
}

.h-dashboard img.logo {
  max-width: 140px;
  margin: 30px 0px;
}

.nav-link.admin {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.h-icon {
  max-height: 20px;
}

footer.footer {
  background-color: rgba(192, 192, 192, 0.2);
  border-radius: 20px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  padding: 13px 20px;
}

.h-dashboard nav.navbar {
  border-radius: 20px;
  margin: 10px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 60px;
  background: rgba(192, 192, 192, 0.2);
}

.h-dashboard #sidebar-wrapper .list-group {
  min-width: 180px;
}

.dashboard-inner {
  background: rgba(192, 192, 192, 0.2);
  border-radius: 20px;
  padding: 10px 10px 45px;
  min-width: 100%;
}

.nav-link.admin {
  font-size: 15px;
  margin-top: 3px;
}

.h-dashboard .dropdown-toggle::after {
  margin-left: 7px;
  display: none;
}

.h-dashboard div.list-group-item {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  background: rgba(192, 192, 192, 0.2);
  border: 0;
  border-radius: 20px;
}

.h-dashboard div.list-group-item-selected {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  background: #004169;
  border: 0;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

.d-header-menu .dropdown-menu {
  margin-top: 10px;
  padding: 0px 0px;
  border: 1px solid transparent;
  border-radius: 10px;
}

.d-header-menu .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 10px;
}

.d-header-menu .nav-link {
  color: #1e2125;
  border: 1px solid transparent;
}

.d-header-menu .dropdown-menu {
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
}

.d-header-menu .dropend .dropdown-toggle::after {
  margin-left: 65px;
  display: none;
}

.d-header-menu img.icon {
  height: 13px;
  width: auto;
}

.dropdown-menu.dropdown-menu-end.show {
  border: 1px solid #f2f2f2;
  border-radius: 0;
  min-width: 215px;
  margin-top: 10px;
}

.h-dashboard.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 10px;
}

.h-dashboard .dropdown-menu-end[data-bs-popper] {
  left: 0;
  right: auto;
}

.dashboard-img-div {
  width: 100%;
  background: linear-gradient(90deg,
      rgba(255, 177, 195, 1) 0%,
      rgba(255, 203, 112, 1) 100%);
  min-height: 146px;
  border-radius: 20px;
  padding: 0px;
}

.dashboard-img-div img {
  width: 100%;
  border-radius: 20px;
}

.dashboard-text-div {
  margin: 0;
  padding: 0;
}

.progress {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  height: 6px !important;
}

.progress-bar {
  width: 100%;
}

.progress-bar__bar {
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 14px;
}

.progress-bar__progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 15px;
  height: 10px;
  border-radius: 10px;
  background-color: green;
  transition: background-color 0.3s ease;
}

.progress-bar__progress--step-1 {
  background-color: red;
}

.progress-bar__progress--step-2 {
  background-color: yellow;
}

.progress-bar__progress--step-3 {
  background-color: green;
}

.progress-bar__progress--step-completed {
  background-color: yellow;
}

.progress-bar__tick-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  opacity: 0;
  background-color: green;
  transition: all 0.15s ease;
  transform: scale(0);
}

.progress-bar__progress--step-completed & {
  opacity: 1;
  transform: scale(1);
}

.progress-bar__tick {
  width: 15px;
  height: 16px;
  fill: white;
}

.progress-bar__label {
  display: block;
  font-size: rem(16);
  text-align: center;
  color: white;
}

.progress-bar__label--left {
  text-align: left;
}

.dashboard-text-div .secheading {
  font-size: 18px;
}

.h-dashboard .btn-primary:hover {
  /* border: 0px solid #f2bb3f !important; */
}

#sidebar-wrapper .dropdown .nav-link:focus,
.dropdown .nav-link:hover {
  background-color: transparent;
}

.header svg.icon.icon-lg {
  width: 22px;
  margin: 7px;
}

.time-text {
  justify-content: center;
  background: green;
  border-radius: 20px;
  color: #fff;
}

.time-text-left.mr-4 {
  min-width: 220px;
  margin-right: 0px;
}

.h-dashboard p {
  margin: 5px 0;
}

.h-dashboard .dashboard-text-div .button_common.btn.btn-primary {
  padding: 6px 8px 8px;
  max-width: 150px;
  max-height: 35px;
  margin: 0;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.list-item {
  display: flex;
  max-width: 100%;
  min-height: 100%;
}

.badge-text {
  text-align: center;
  background: #fff;
  padding: 4px;
  font-size: 13px;
  width: 103px;
  border-radius: 81px;
  display: inline-block;
}

.h-dashboard .toggleIcon {
  position: absolute;
  top: 0px;
  left: 225px;
  right: 0;
  bottom: 0;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

.h-dashboard h2.heading,
.h-dashboard .secheading {
  font-size: 20px;
}

.h-dashboard h2.heading {
  margin-left: 20px;
  color: #125049;
}

.h-dashboard .navbar .secheading {
  font-style: italic;
}

.h-dashboard svg.w-8.h-8.xl\:w-5.xl\:h-5 {
  width: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.h-dashboard .icon-bell:before {
  content: "\e027";
}

.h-dashboard .dropdown button,
.h-dashboard .dropdown .btn-primary {
  background: transparent;
  border: 0;
  padding: 0;
  padding-right: 10px;
}

.h-dashboard a.dropdown-item.preview-item {
  margin-bottom: 0;
  padding: 5px 5px;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
  line-height: 1;
  display: flex;
  align-items: center;
}

.h-dashboard .dropdown-header {
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
}

.mdi-home:before {
  content: "\F2DC";
}

.h-dashboard .dropdown-item.active,
.h-dashboard .dropdown-item:active {
  text-decoration: none;
  background-color: transparent;
}

.h-dashboard a.dropdown-item.preview-item:nth-child {
  border-bottom: 0px solid #dee2e6;
}

.h-dashboard .dropdown-divider {
  margin: 0;
}

.h-dashboard .progress,
.h-dashboard .progress-stacked {
  --bs-progress-bar-bg: #f2bb3f;
  background: #fff;
}

.h-dashboard span.badge.badge-pill {
  border: 1px solid #f2bb3f;
  color: #f2bb3f;
  font-size: 12px;
  font-size: 12px;
  line-height: 1;
  padding: 0.375rem 0.5625rem;
  font-weight: normal;
}

.dropdown-header {
  padding: 0 0 10px;
}

.h-dashboard .profile-pic,
.dropdown-header img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 10px;
}

.h-dashboard .dropdown .btn-primary:hover,
.h-dashboard .dropdown .btn-primary {
  background: transparent !important;
  border-radius: 0px;
  border: 0px;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 0;
}

.notes-right-div {
  background: #ffe4b5;
  border: 0;
  padding: 20px;
}

.float-right {
  float: right;
}

.switches-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
}

.float-left {
  float: left;
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.notes-left-div,
.notes-right-last-div {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.dashboard-inner {
  position: relative;
}

.send-btn {
  all: unset;
}

.send-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* position: absolute; */
  bottom: 15px;
  width: calc(100% - 30px);
  position: fixed;
  bottom: 0px;
  align-items: end;
}

.send-box-input {
  width: calc(100% - 100px);
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  background: rgb(255, 255, 255, 50%);
  border: 0;
  outline: none;
  color: #f2f2f2 !important;
  padding: 12px;
  font-size: 16px;
  margin: 0 0 20px 0;
  resize: vertical;
  max-height: 200px;
  font-weight: 2px;
}

.send-box:focus {
  font-size: 16px;
}

.send-box-input::placeholder {
  color: #f2f2f2 !important;
}

.switches-div {
  border-radius: 20px;
  margin: 10px 0;
  padding: 10px;
}

.send-box-icon {
  background: #5f0f40;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  text-align: center;
  padding: 5px 0;
}



.btn.btn--send {
  min-width: 28px;
  color: #fff;
  padding: 0;
  align-items: center;
  padding: 4px 0px;
  transform: translateX(94);
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background: transparent;
  border: 0;
  margin-left: -5px;
}

.ai-div {
  /* background-image: url(https://i.pinimg.com/564x/ec/5c/d9/ec5cd9f0428c94b39a271f0d73fa5e50.jpg);
  */
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  padding: 10px 0;
  position: relative;
}

.ai-div .therapist-content-center h2 {
  color: #fff;
  font-size: 37px;
}

.ai-div .therapist-content-center p {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.ai-div .therapist-content-center {
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 80px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  min-height: 470px;
  overflow-y: auto;
}

.ai-div .toggle {
  cursor: pointer;
  display: inline-block;
}

.ai-div .toggle-switch {
  display: inline-block;
  background: #fff;
  border-radius: 16px;
  width: 66px;
  height: 28px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.ai-div .toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.ai-div .toggle-switch:before {
  display: block;
  background: var(--bg-color);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 22px;
  height: 22px;
  position: absolute;
  top: 2px;
  left: 4px;
  transition: left 0.25s;
}

.ai-div .toggle:hover .toggle-switch:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.ai-div .toggle-checkbox:checked+.toggle-switch {
  background: #fff;
}

.ai-div .toggle-checkbox:checked+.toggle-switch:before {
  left: 40px;
}

.ai-div .toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.ai-div .toggle-label {
  margin: 0;
  position: relative;
  top: 2px;
  color: #fff;
  margin-right: 5px;
  font-size: 16px;
}

.ai-div .mic {
  max-width: 25px;
  margin: 4px;
  filter: brightness(0) invert(1);
}

.ai-div .icons li {
  background: none repeat scroll 0 0 #fff;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

button.button_common.btn.btn-primary.notes-btn {
  float: right;
  max-width: 155px;
  margin-right: 10px;
  padding: 8px 15px;
}

.h-head .heading {
  float: left;
  padding-top: 25px;
}

.sessions-tabs .nav.nav-tabs {
  border: 0 !important;
}

.sessions-tabs .nav-tabs .nav-link {
  background: transparent !important;
  border: 0 !important;
  color: #828583;
  display: inline-block;
}

.sessions-tabs li.nav-item {
  border-bottom: 1px solid #ccc;
  min-width: 130px;
  text-align: center;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid #f2bb3f !important;
  min-width: 130px;
  border-radius: 0;
}

.chat-tabs .nav.nav-tabs {
  padding-left: 25px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px #f2f2f2 !important;
  border-radius: 20px;
}

.chat-tabs li.nav-item {
  border-bottom: 0;
  min-width: 50%;
  background: #fff;
  color: #f2bb3f;
  border-radius: 20px;
}

.chat-tabs .nav-tabs .nav-link.active,
.chat-tabs .nav-tabs .nav-item.show .nav-link {
  background: #f2bb3f !important;
  color: #fff;
  border: 0 !important;
  border-radius: 20px;
  float: right;
}

/* Active tab */
.sessions-tabs button.active>a,
.sessions-tabs button.active>a:hover,
.sessions-tabs button.active>a:focus {
  background: transparent;
  border: 0;
  color: #0a0b0d;
  font-weight: 800;
}

.slot-time {
  cursor: pointer;
  padding: 0 10px;
  border: 0;
  background: rgba(192, 192, 192, 0.2);
  min-width: 127px;
  text-align: center;
  color: #205a53;
  display: flex;
  align-items: center;
  max-height: 65px;
  border-radius: 50px;
  max-width: 320px;
  justify-content: space-between;
}

.slot-time .slot {
  color: #205a53;
  font-size: 17px;
  font-weight: 800;
}

.text-div-sessions {
  padding: 0 10px;
}

.slot-div-date {
  color: #424242;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.slot-div-name {
  color: #205a53;
  font-size: 14px;
  font-weight: 800;
}

.toggle-menu {
  display: none;
}

.clock svg {
  width: 25px;
  margin: 0 5px;
  fill: #205a53;
}

/*

*/
.auto-grid {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
  width: 100%;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 1em;
}

.auto-grid li {
  text-align: center;
}

.sessions-text,
.sessions-tabs .nav.nav-tabs {
  padding-left: 25px;
}

.people-list .search {
  padding: 0px;
}

.people-list input {
  border-radius: 20px;
  background-color: #fff;
  border: 0;
  outline: none;
  color: #1d2129;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  margin: 10px 0 5px;
  height: 45px;
}

.people-list .fa-search {
  position: relative;
  left: -25px;
}

.people-list ul {
  padding: 0px;
  height: 770px;
}

.people-list ul .circle {
  height: 18px;
  width: 18px;
  background: #800000;
  border-radius: 100%;
  display: inline-block;
  float: right;
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 2px;
}

.people-list ul li {
  padding-bottom: 20px;
}

.people-list img {
  float: left;
}

.people-list .about {
  float: left;
  margin-top: 8px;
}

.people-list .about {
  padding-left: 8px;
  font-size: 14px;
}

.people-list .status {
  color: #92959e;
  float: right;
  margin-top: 8px;
}

.chat {
  color: #434651;
  min-width: 100%;
  width: 100%;
}

.status .day {
  font-size: 14px;
}

.chat .chat-header {
  padding: 10px;
  border-radius: 20px;
  font-size: 14px;
  margin-bottom: 15px;
}

.sessions-tabs {
  min-width: 100%;
  width: 100%;
}

.activity-width h4 {
  font-size: 19px;
  line-height: 30px;
}

.chat .chat-header img {
  float: left;
  border-radius: 100%;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}

.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}

.chat .chat-header .chat-num-messages {
  color: #92959e;
  font-size: 14px;
}

.chat .chat-header .button {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 20px;
  display: inline-block;
  width: 100%;
}

.chat .send-box-input {
  width: calc(100% - 50px);
}

.chat .chat-history img,
.people-list ul img {
  border-radius: 100%;
}

.chat .chat-history h6 {
  color: #828583;
  font-weight: 500;
  font-size: 15px;
}

.chat .chat-history li {
  width: 100%;
  max-width: 478px;
}

.chat .chat-history li:nth-child(odd) {
  float: right;
}

.chat .chat-history li:nth-child(even) {
  float: left;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
  font-size: 12px;
  margin-bottom: 0;
}

.chat .chat-history .message {
  color: #000;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 20px;
  margin-bottom: 30px;
  width: 90%;
}

.chat .chat-history .my-message {
  background: #fff9f1;
  color: #0a0b0d;
}

.chat .chat-history .other-message {
  background: #fff;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #fff;
  left: 93%;
}

.chat .chat-message {
  padding: 30px;
}

.chat .chat-message textarea {
  font: 14px/22px "Lato", Arial, sans-serif;
  width: calc(100% - 100px);
  border-radius: 20px;
  background-color: #fff9f1;
  border: 0;
  outline: none;
  color: #fff;
  padding: 12px;
  font-size: 14px;
  margin: 0px;
  height: 45px;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.chat .chat-message button {
  float: right;
  color: #94c2ed;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #f2f5f8;
}

.chat .chat-message button:hover {
  color: #75b1e8;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.note-msg-text {
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-size: 13px;
}

.pdf-div {
  background: #fff !important;
  padding: 12px !important;
}

.activity-width {
  max-width: 450px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.account-dropdown .dropdown-menu.show {
  padding: 5px;
  background: #898989 !important;
  min-width: 145px;
  color: #fff;
  height: auto;
  margin-top: 50px;
}

li.notification-dropdown .dropdown-menu.show {
  background: #898989 !important;
  padding: 5px;
  height: auto;
  margin-top: 50px;
}

li.notification-dropdown .dropdown-menu.show a {
  padding: 0px;
  margin-bottom: 10px;
  background: transparent !important;
}

.account-dropdown .dropdown-menu.show a {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  background: transparent;
}

.row-flex>div[class*="col-"] div {
  width: 100%;
  background: #fff9f1;
  border: 0;
  border-radius: 20px;
  padding: 5px;
  align-items: center;
  max-width: 206px;
  margin-bottom: 10px;
}

.payment-table {
  max-height: 470px;
  overflow-y: scroll;
}

.payment-table .table {
  border-collapse: separate;
}

.payment-table table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.payment-table th,
.payment-table td {
  background: #eee;
  padding: 1rem;
  border: 1px solid;
}

.payment-table th+th,
.payment-table td+td {
  border-left: 0;
}

.payment-table tr {
  background-color: #fff9f1;
}

.payment-table td:first-child,
.payment-table th:first-child {
  border-radius: 20px 0 0 20px;
}

.payment-table td:last-child,
.payment-table th:last-child {
  border-radius: 0 20px 20px 0;
}

.dashboard-inner .payment-table th {
  background-color: #000;
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

.dashboard-inner .payment-table th,
.dashboard-inner .payment-table td {
  background-color: transparent;
  border-bottom-width: 0;
  padding: 15px 0;
  border: 0;
}

.dashboard-inner .payment-table thead th {
  background-color: #fff;
  padding: 15px 0;
  color: #125049;
  font-weight: 600;
  font-size: 14px;
}

.dashboard-inner .payment-table thead tr {
  border-radius: 20px;
  padding: 15px 0;
}

.flex-col {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 100%;
  -ms-flex: 1 100%;
  flex: 1 100%;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.playground-img-div {
  width: 100%;
  background: rgba(255, 203, 112, 1);
  min-height: 179px;
  border-radius: 20px;
  padding: 10px;
}

.card-title {
  text-align: left;
  color: #205a53;
  font-size: 16px;
  font-weight: 500;
}

.pdf-icon {
  font-size: 37%;
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 40px;
  height: 50px;
  background-color: #eee;
  background-image: url("https://i.imgur.com/lZ5SgDE.png");
  background-repeat: no-repeat;
  background-size: 66% auto;
  background-position: center 2em;
  border-radius: 0;
  float: left;
  margin-right: 5px;
}

.playground .dashboard .card-body {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
}

img.call-pic {
  border-radius: 100%;
}

.wrapper-play {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
}

.videos {
  max-height: 380px;
  overflow-y: scroll;
}

.wrapper-play .box {
  width: 100%;
  min-height: 128px;
  margin-bottom: 40px;
}

.preview-item-joined {
  background: #fff;
  padding: 10px;
  border-left: 2px solid #f2bb3f;
}

.videos .box {
  margin-bottom: 160px;
}

.profile .card-body {
  background: #fff;
  border-radius: 20px;
  padding: 5px;
}

.makepayment {
  background: #fff;
  max-width: 300px;
  padding: 5px;
}

.makepayment .playground-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}

.makepayment .card-text {
  text-align: left;
  font-size: 13px;
}

.tick-mark-div {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: #f2bb3f;
  margin: 0px auto;
  position: relative;
}

.tick-mark-div::before {
  position: absolute;
  left: 29px;
  top: 15px;
  display: inline-block;
  content: "";
  transform: rotate(45deg);
  height: 35px;
  width: 17px;
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
}

.payment-model .modal-dialog,
.payment-model .modal-content {
  max-width: 320px;
  background: rgb(192, 192, 192, 0.9);
  border-radius: 20px;
  padding: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.payment-model .modal-body {
  padding: 50px 20px;
}

.modal {
  text-align: center;
}

.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

/* Responsive */
@media (max-width: 600px) {
  .wrapper-play {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1350px) {
  .time-text-left.mr-4 {
    min-width: auto;
    margin-right: 20px;
  }

  .wrapper-play {
    grid-template-columns: repeat(4, 1fr);
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .sessions-tabs li.nav-item {
    min-width: auto;
    float: right;
  }
}

@media (max-width: 1200px) {
  .auto-grid {
    width: auto !important;
    max-width: 100%;
  }

  .h-dashboard .toggleIcon {
    left: 190px;
  }

  .h-dashboard .sidebar-heading {
    min-width: 180px;
  }

  .chat .chat-history {
    padding: 0;
  }

  .chat ul {
    padding-left: 0;
  }

  .h-dashboard #sidebar-wrapper,
  .dropdown-menu.show {
    min-width: 180px;
  }

  .wrapper-play {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }

  #sidebar-wrapper {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 992px) {
  .chat-left-div .nav-tabs {
    max-width: 211px;
  }

  .sessions-tabs .nav-tabs .nav-link {
    font-size: 15px;
  }

  .sessions-text,
  .sessions-tabs .nav.nav-tabs {
    padding-left: 0;
  }

  .pdf-div {
    padding: 2px !important;
  }

  .navbar-nav {
    float: right;
  }

  .ai-div .therapist-content-center {
    padding: 20px;
  }

  li.nav-item.dropdown {
    width: 100%;
    margin: 10px;
  }

  .navbar-nav {
    flex-direction: row !important;
  }

  .navbar-light .navbar-toggler {
    border: 0;
  }

  button#sidebarToggle {
    display: block;
    padding: 0;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
    border: 0;
  }
}

@media (max-width: 766.99px) {
  .toggle-menu {
    position: absolute;
    left: 10px;
    top: 15px;
    display: block;
    width: 100%;
  }

  .header svg.icon.icon-lg {
    width: 27px;
    margin: 7px;
  }

  li.notification-dropdown .dropdown-menu.show,
  li.account-dropdown .dropdown-menu.show {
    margin-top: 52px;
    border-radius: 20px;
  }

  .wrapper-play {
    grid-template-columns: repeat(2, 1fr);
  }

  .h-head .heading {
    padding-top: 0;
  }

  .sessions-text,
  .sessions-tabs .nav.nav-tabs {
    padding-left: 0px;
  }

  .sessions-tabs .nav-tabs .nav-link {
    padding: 10px;
    font-size: 14px;
  }

  .auto-grid {
    grid-auto-rows: 84px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 0;
  }

  .slot {
    padding: 5px;
    margin: 5px;
    min-width: 85px;
    font-size: 14px;
  }

  .clock svg {
    margin: 0;
  }

  button.button_common.btn.btn-primary.notes-btn {
    display: none;
  }

  .time-text-left.mr-4 {
    margin-right: 0;
  }

  .h-dashboard h2.heading {
    min-width: 164px;
    margin-left: 67px;
  }

  .ai-div .therapist-content-center h2 {
    font-size: 22px;
    text-align: center;
  }

  .ai-div .therapist-content-center p {
    /* padding: 0; */
  }

  .h-dashboard .toggleIcon {
    left: 0px;
    width: 100%;
    background: #fff9f1;
  }

  .h-dashboard #sidebar-wrapper {
    background: #f2bb3f;
    border-radius: 0;
    border: 0;
    max-width: 100%;
    overflow: hidden;
    margin-top: 15px;
  }

  .h-dashboard img.logo {
    max-width: 140px;
    margin: 0;
    background: #f2bb3f;
    display: none;
  }

  .h-dashboard .sidebar-heading {
    background: #f2bb3f;
  }
}

@media (max-width: 480px) {
  footer.footer {
    display: block;
  }

  .logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1.heading {
    margin-left: 10px;
    text-align: center;
  }

  .subheading {
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .heading-discription {
    margin-left: 25px;
    margin-bottom: 20px;
    text-align: center;
  }

  .register {
    margin-left: 1px;
    margin-top: 10px;
  }

  .otp-div {
    margin-left: 1px;
  }

  .ai-div .therapist-content-center {
    height: calc(100vh - 200px);
    min-height: 100%;
  }

  .wrapper-play {
    grid-template-columns: repeat(1, 1fr);
  }

  .h-dashboard h2.heading {
    margin-left: 52px;
  }
}

.h3color {
  color: white;
}

.previouschat-dropdown {
  max-height: 350px;
  overflow-y: scroll;
}

/* .dark-theme {
  background: linear-gradient(to bottom right, #5f0f40, #0f4c5c);
} */

.dark-theme .send-box-input {
  background-color: rgb(255, 255, 255, 20%);
  color: #fff;
}

/* Dark Theme  Desktop*/
.dark-theme {
  background: linear-gradient(to bottom right, #5f0f40, #0f4c5c);
  min-height: 615px;
  height: 100vh;
}

.dark-theme .chat-trasmitting-light {
  display: block;
  background-color: rgb(0, 0, 0, 20%);
  max-width: 500px;
  text-align: right;
  padding: 15px;
  border-radius: 50px;
}

.dark-theme.chat-trasmitting-light p {
  font-size: 16px;
  color: #f2f2f2 !important;
  font-weight: 500;
}

.dark-theme.chat-trasmitting-light {
  /* position: relative;
  right: -380px; */
}

.dark-theme .trasmitting-text {
  word-wrap: break-word;
}

.dark-theme .chat-reciving-light {
  display: block;
  background-color: rgb(0, 0, 0, 20%);
  max-width: 500px;
  text-align: right;
  padding: 15px;
  border-radius: 50px;
}

.dark-theme .chat-reciving-light p {
  font-size: 14px;
  color: #f2f2f2 !important;
  font-weight: 500;
}

.dark-theme .chat-reciving-light {
  /* position: relative;
  left: -400px;
  z-index: -1; */
}

.dark-theme .receving-text {
  word-wrap: break-word;
  padding: 10px;
}

/* Light */

.light-theme {
  background: linear-gradient(to bottom right, #d1c6f3, #e9bcac);
  min-height: 615px;
  height: 100vh;
}

.light-theme .chat-trasmitting-light {
  display: block;
  background-color: rgb(255, 255, 255, 50%);
  max-width: 500px;
  text-align: right;
  padding: 25px;
  border-radius: 50px;
  /* margin-bottom: 50px; */
}

.light-theme .chat-trasmitting-light p {
  font-size: 16px;
  color: #1a1a1a !important;
  font-weight: 500;
}

.light-theme .chat-trasmitting-light {
  /* position: relative;
  right: -400px; */
}

.light-theme .trasmitting-text {
  word-wrap: break-word;
}

.light-theme .chat-reciving-light {
  display: block;
  background-color: rgb(255, 255, 255, 50%);
  /* width: 450px; */
  max-width: 500px;
  text-align: right;
  padding: 25px;
  border-radius: 50px;
  z-index: -1;
}

.light-theme .chat-reciving-light p {
  font-size: 16px;
  color: #1a1a1a !important;
  font-weight: 500;
}

.light-theme .chat-reciving-light {
  /* position: relative;
  left: -380px;
  z-index: -1; */
}

.light-theme .receving-text {
  word-wrap: break-word;
  padding: 10px;
}

/* Media query for chat */

@media screen and (max-width: 600px) {

  /* Light theme */
  .light-theme {
    background: linear-gradient(to bottom right, #d1c6f3, #e9bcac);
    min-height: 99vh;
  }

  .light-theme .chat-trasmitting-light {
    display: block;
    background-color: rgb(255, 255, 255, 50%);
    max-width: 230px;
    text-align: right;
    padding: 15px;
    border-radius: 50px;
  }

  .light-theme .chat-trasmitting-light p {
    font-size: 16px;
    color: #1a1a1a !important;
    font-weight: 500;
  }

  .light-theme .chat-trasmitting-light {
    /* position: relative;
    right: -32%; */
  }

  .light-theme .trasmitting-text {
    word-wrap: break-word;
  }

  .light-theme .chat-reciving-light {
    display: block;
    background-color: rgb(255, 255, 255, 50%);
    /* width: 230px; */
    max-width: 230px;
    text-align: right;
    padding: 15px;
    border-radius: 50px;
    z-index: -1;
  }

  .dark-theme .chat-reciving-light {
    max-width: 230px;
  }

  .dark-theme .chat-trasmitting-light {
    max-width: 230px;
  }

  .light-theme .chat-reciving-light p {
    font-size: 14px;
    color: #1a1a1a !important;
    font-weight: 500;
  }

  .light-theme .chat-reciving-light {
    /* position: relative;
    left: 0px; */
  }

  .light-theme .receving-text {
    word-wrap: break-word;
    padding: 10px;
  }

  /* Dark Theme */
  .dark-theme {
    background: linear-gradient(to bottom right, #5f0f40, #0f4c5c);
  }

  .dark-theme .chat-trasmitting-light {
    display: block;
    background-color: rgb(0, 0, 0, 20%);
    max-width: 230px;
    text-align: right;
    padding: 15px;
    border-radius: 50px;
  }

  .dark-theme .chat-trasmitting-light p {
    font-size: 14px;
    color: #f2f2f2 !important;
    font-weight: 500;
  }

  .dark-theme .chat-trasmitting-light {
    /* position: relative;
    right: -18px; */
  }

  .dark-theme .trasmitting-text {
    word-wrap: break-word;
  }

  .dark-theme .chat-reciving-light p {
    font-size: -347px;
    color: #f2f2f2 !important;
    font-weight: 500;
  }

  .dark-theme .chat-reciving-light {
    /* position: relative;
    left: 0px; */
  }

  .dark-theme .receving-text {
    word-wrap: break-word;
    padding: 10px;
  }

  .therapist-inner-wrapper {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    overflow-x: auto;
  }
}

/* Payment success pop up */
/* .payment-successfull-outer {
  height: 380px;
  background-color: #db9d52;
  border-radius: 20px;
  border: 8px solid #e5d2bc;
  width: 300px;
  position: absolute;
}
.payment-success-inner {
  background-color: #9d928459;
  height: 300px;
  width: 325px;
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(7px);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.payment-success-inner.d-flex.align-items-center {
  position: absolute;
  left: -20px;
  top: 28px;
}
.payment-success-inner-wrapper h1 {
  font-size: 22px;
}
.success-checkbox-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #f4bd3f;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.success-checkbox-icon i {
  font-size: 26px;
} */
.ai-div {
  z-index: 999;
}

.chat-trasmitting-light {
  z-index: -1;
}

/* Media query for input type for mobile view */
@media screen and (max-width: 768px) {
  .send-box-input {
    width: 61;
  }

  .h-dashboard #sidebar-wrapper,
  .dropdown-menu.show {
    min-width: 214px;
    overflow-x: auto;
    width: 100vw !important;
  }

  /* .previouschat-dropdown.dropdown-menu.show {
    position: absolute !important;
    border: 5px solid red;
    width: 85% !important;
    overflow-x: scroll !important;
    max-width: 500px !important;
  } */

  .previouschat-dropdown .dropdown-menu.show {
    right: -9px !important;
    transform: inherit !important;
    height: auto;
    border-radius: 20px;
    border: 8px solid red;
    width: 234px;
    overflow: scroll;
    top: 44px;
  }

  .therapist-inner-wrapper {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    overflow-x: auto;
  }
}

/* .send-box-input input.send-box-input::placeholder {
  color: #000 !important;
} */

.chatbox {
  display: flex;
  flex-direction: column;
}

.chatbox-rec {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 0px 10px;
}

.chatbox-send {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0px 10px;
}

.transmiting-light-Text {
  display: block;
  background-color: #480f35;
  width: 450px;
  max-width: 250px;
  text-align: right;
  padding: 25px;
  border-radius: 50px;
  margin-bottom: 30px;
}

.recieving-light {
  display: block;
  background-color: #ede1ec;
  width: 230px;
  max-width: 250px;
  text-align: right;
  padding: 15px;
  border-radius: 50px;
  margin-bottom: 50px;
}

.therapist-inner-wrapper {
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .inner-content {
    max-width: 64%;
    margin-left: 18%;
    height: 70vh;
    position: relative;
  }
}

.inner-content {
  height: 70vh;
  position: relative;
}

.chat-histroy-box {
  max-height: 300px;
  overflow-y: scroll;
}

.chat-histroy-box div {
  position: relative;
  z-index: 100;
}

.popup-content {
  margin: auto;
  background: linear-gradient(to bottom right, #5f0f40, #0f4c5c) !important;
  width: 50%;
  padding: 5px;
  border: none !important;
  color: rgb(255, 255, 255);
  position: absolute !important;
  margin: auto;
  pointer-events: auto;
  right: 38px;
  top: 31px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role="tooltip"].popup-content {
  width: 65% !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 1) !important;
  margin-left: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.logout-btn {
  border: none;
  border-radius: 26px;
  padding: 4px 15px;
  background: #f2bb3f;
  color: rgb(255, 255, 255);
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 6px;
}

.logout-img {
  max-width: 20px;
}

/* 
//switch timg */

.Avail-Time {
  display: flex;
  justify-content: start;
  position: absolute;
  right: 48px;
  top: 15%;
}

.Time-Display {
  text-align: center;
}

.Time {
  vertical-align: middle;
}

.switch {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d9b5d6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #db95db;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(75px);
  -ms-transform: translateX(75px);
  transform: translateX(75px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 42%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
  width: 61px;
  line-height: 13px;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 62%;
  font-size: 15px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* / Rounded sliders / */
.slider.round {
  border-radius: 34px;
}

.time {
  margin-left: 5px;
}

.slider.round:before {
  border-radius: 50%;
}

/* //style typing 3dots*/

.dot-pulse {
  position: absolute;
  left: -9494px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #db95db;
  color: #db95db;
  box-shadow: #db95db 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #db95db;
  color: #db95db;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.loading {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  right: 26%;
  width: 50%;
  font-size: 16px;
  color: #1a1a1a !important;
  font-weight: 500;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: #eae2f3;
  display: inline-block;
  height: 14px;
  margin: 10px;
  width: 14px;
  border-radius: 15px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.3s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.5s;
}

@keyframes dot {
  0% {
    background-color: #eae2f3;
    transform: scale(1);
  }

  50% {
    background-color: #eae2f3;
    transform: scale(1.3);
  }

  100% {
    background-color: #eae2f3;
    transform: scale(1);
  }
}

.dot-container {
  width: 10%;
}

@media only screen and (min-width: 900px) {
  .loading {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    right: 30%;
    width: 50%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* //modal */
.body {
  font-family: "Varela Round", sans-serif;
}

.modal-confirm {
  color: #636363;
  width: 400px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .modal-body {
  color: #999;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.audio-recorder {
  height: 44px;
  width: 44px !important;
  border-radius: 25px !important;
  margin-bottom: 25px;
  background-color: rgb(255, 255, 255, 20%) !important;
  box-shadow: 0 0px 0px #bebebe !important;
}

.audio-recorder-mic {
  box-sizing: content-box;
  cursor: pointer;
  height: 16px;
  color: #000;
  padding: 14px !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.send_btn {
  background-color: rgb(255, 255, 255, 20%) !important;
  width: 44px;
  height: 44px;
  border-radius: 50% !important;
  text-align: center;
  padding: 5px 0;
  border: none !important;
  position: relative;
}

.send_btn:hover {
  background-color: rgb(255, 255, 255, 20%) !important;
  border: none !important;
}

.send_btn svg {
  transform: rotate(45deg);
  height: 26px !important;
  position: absolute;
  left: 6.5px;
  top: 9px;
}

.send_btn:hover {
  background-color: rgb(255, 255, 255, 20%) !important;
  border: none !important;
}